import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-404.png'
import MainMessage from '@/components/common/MainMessage'
import LinkButton1 from '@/components/common/LinkButton1'

const NotFoundPage = props => (
  <Root>
    <SEO title="404 お探しのページは見つかりませんでした"/>
    <Head>
      <PageTitle src={imgTitle}>NOT FOUND</PageTitle>
      <MainMessage
        body={(
          <Fragment>大変申し訳ございません。<br />お探しのページまたはファイルは見つかりませんでした。</Fragment>
        )}
      />
    </Head>
    <Main>
      <Row css={styles.Link}><Button to="/" component={Link} scale={800}>サイトトップへ</Button></Row>
    </Main>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 80px 0;
  @media ${mq.and(mobile)} {
    padding: 40px 0;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  Link: css`
    padding: 0 0 80px;
    @media ${mq.and(mobile)} {
      padding: 0 0 40px;
    }
    text-align: center;
  `
}

export default PageBase(NotFoundPage)
